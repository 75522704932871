import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Vue3Lottie from 'vue3-lottie'
import { loadFonts } from './plugins/webfontloader'


loadFonts()


createApp(App)
  .use(vuetify)
  .use(Vue3Lottie, { name: 'LottieAnimation' })
  .mount('#app')