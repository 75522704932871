<template>
  <div class="main">
    <div class="first">
      <v-col>
        <v-img class="img-logo" src="../assets/logo.svg" contain />
        <h1 align="left">Revolutionize your <br />wardrobe with</h1>
        <h1 class="text-name">WEARIN</h1>
        <p>POWERED BY LOCAL BRANDS AND<br />SOME TALNTED DESIGNERS.</p>
        <p class="text-ava">Only Available on App Store and Google Play</p>
        <v-row class="row-btn-group" align="left">
          <v-btn
            icon
            class="footer-btn"
            href="https://apps.apple.com/eg/app/wearin/id1633358625"
            elevation="0"
          >
            <v-icon color="white">mdi-apple</v-icon>
          </v-btn>
          <v-btn
            icon
            class="footer-btn"
            href="https://play.google.com/store/apps/details?id=com.wearin"
            elevation="0"
          >
          <v-icon color="white">mdi-google-play</v-icon>
          </v-btn>
        </v-row>
        <!-- <h6 class="text-rights">Copyright © 2023 Wearin LLC. All rights reserved.</h6> -->
      </v-col>
    </div>
    <div class="second">
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      title: "Example Title", // replace with your own title
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac dui sit amet diam mollis ornare ac et lectus.", // replace with your own description
    };
  },
  methods: {},
};
</script>

<style>
body {
  overflow: hidden;
  font-family: "Tilt Neon", cursive;
}
.img-logo {
  width: 60px;
  height: 60px;
  margin-top: 50px;
  margin-left: 4%;
  margin-bottom: 3%;
}
h1 {
  font-size: 60px;
  color: white;
  padding-left: 3%;
}
.text-name {
  font-size: 60px;
  color: #d8213d;
}
p {
  color: white;
  font-size: 16px;
  padding-left: 3%;
}
.main {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.first {
  background-color: #000000;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}
.second {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
}
.row-btn-group {
  margin-top: 1%;
  margin-left: 2.2%;
}
.text-ava {
  margin-top: 8%;
  font-size: 20px;
}
.footer-btn {
  background-color: black;
}
</style>
